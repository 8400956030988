import React from 'react';
import { Provider } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import ThemeWrapper from './theme-wrapper';
import store from './src/store';
import AppProvider from './src/components/AppProvider';
const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PK);

export default ({ element }) => {
  const dropActions = require('./src/actions/dropActions');
  const userActions = require('./src/actions/userActions');
  store.dispatch(userActions.watchFirebaseAuth());
  store.dispatch(dropActions.loadDrops());
  return (
    <Provider store={store}>
      <AppProvider>
        <ThemeWrapper>
          <Elements stripe={stripePromise}>
            {element}
          </Elements>
        </ThemeWrapper>
      </AppProvider>
    </Provider>
  );
};

import React from 'react';
import { ThemeProvider } from 'styled-components';
import { useMediaQuery } from '@material-ui/core';
const mobile_theme = {
  'text-color': '#2B2B2B',
  'primary-color': '#1039DD',
  'text-field-background': '#F2F2F2',
  'app-background-color': '#FFFFFF',
};

const desktop_theme = {
  'text-color': '#2B2B2B',
  'primary-color': '#1039DD',
  'text-field-background': '#F2F2F2',
  'app-background-color': '#FFFFFF',
};

Object.keys(mobile_theme).forEach((key) => {
  if (mobile_theme[key].match(/#/)) {
    mobile_theme[key] = hexToRGB(mobile_theme[key]);
  }
});

export default function ThemeWrapper({ children }) {
  const mobileMediaQuery = useMediaQuery('(max-width: 500px)');
  return (
    <ThemeProvider theme={mobileMediaQuery ? mobile_theme : desktop_theme}>
      {children}
    </ThemeProvider>
  );
}

function hexToRGB(h) {
  let r = 0, g = 0, b = 0;

  // 3 digits
  if (h.length == 4) {
    r = '0x' + h[1] + h[1];
    g = '0x' + h[2] + h[2];
    b = '0x' + h[3] + h[3];

    // 6 digits
  } else if (h.length == 7) {
    r = '0x' + h[1] + h[2];
    g = '0x' + h[3] + h[4];
    b = '0x' + h[5] + h[6];
  }

  return 'rgb(' + +r + ',' + +g + ',' + +b + ')';
}
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { showModal, hideModal } from '../../actions/modalActions';
// import { setLoading } from '../../actions/loadingActions';
import { getPageQueriesFromPath } from './helpers';

export const AppContext = React.createContext({});

function AppProvider({
  children, pageData, showModal, hideModal, isLoading,
}) {
  const [pageQueries, setPageQueries] = useState({});
  const url = typeof(window) !== 'undefined' ? window.location : '';

  useEffect(() => {
    const _pageQueries = getPageQueriesFromPath(url);
    setPageQueries(_pageQueries);
  }, [url]);

  const deviceType = useMediaQuery('(max-width:500px)') ? 'mobile' : 'desktop';
  const state = {
    actions: { showModal, hideModal },
    state: {
      pageQueries, deviceType, isLoading,
    },
    data: pageData,
  };
  return (
    <AppContext.Provider value={state}>
      {children}
    </AppContext.Provider>
  );
}

const mapDispatchToProps = { showModal, hideModal };
const mapStateToProps = (state) => ({ ...state.appReducer });

export default connect(mapStateToProps, mapDispatchToProps)(AppProvider);
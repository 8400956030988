// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-admin-js": () => import("./../../../src/templates/admin.js" /* webpackChunkName: "component---src-templates-admin-js" */),
  "component---src-templates-drops-js": () => import("./../../../src/templates/drops.js" /* webpackChunkName: "component---src-templates-drops-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-login-js": () => import("./../../../src/templates/login.js" /* webpackChunkName: "component---src-templates-login-js" */),
  "component---src-templates-terms-of-service-js": () => import("./../../../src/templates/terms-of-service.js" /* webpackChunkName: "component---src-templates-terms-of-service-js" */)
}


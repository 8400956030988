import * as types from '../actions/actionTypes';

const initialState = { isLoading: false };

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.IS_LOADING: {
    return {
      ...state,
      isLoading: action.isLoading,
    };
  }
  default:
    return state;
  }
};

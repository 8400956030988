/* global heap */
import { navigate } from 'gatsby';
import {
  authStateChanged, signOut, watchDocumentChanges,
} from '../helpers/Firebase';
import * as types from './actionTypes';
import { loading } from './loadingActions';

export function loadUserData(user, oneTime) {
  return (dispatch) => {
    if (user && user.uid) {
      watchDocumentChanges(`users/${user.uid}`, (accountData) =>{
        dispatch({
          type: types.GET_ACCOUNT_DATA,
          accountData,
        });
      }, oneTime);
    }
  };
}

export function login(user) {
  return dispatch => {
    if (user && !user.isAnonymous) {
      dispatch({
        type: types.GET_USER,
        user,
      });
      dispatch(loadUserData(user, true));
      navigate('/');
    }
  };
}

export function watchFirebaseAuth() {
  return dispatch => new Promise((resolve) => {
    dispatch(loading(true));
    authStateChanged((user) => {
      if (user && !user.isAnonymous) {
        heap.identify(user?.uid);
        dispatch(loadUserData(user));
        dispatch({ type: types.GET_USER, user });
      }
      dispatch(loading(false));
      resolve();
    });
  });
}

export function logout() {
  return async dispatch => {
    await signOut();
    dispatch({
      type: types.GET_USER,
      user: null,
    });
    navigate('/');
  };
}
import { makeRequest, API_URL } from '../helpers/Fetch';
import * as types from './actionTypes';

export function loadDrops() {
  return async dispatch => {
    const drops = await makeRequest(`${API_URL}drops`).send();
    drops.sort((a, b) => (b.date || 0) - (a.date || 0));
    dispatch({
      type: types.GET_DROPS,
      drops,
    });
  };
}
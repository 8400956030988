import * as types from '../actions/actionTypes';

const initialState = { user: null, accountData: null };

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_USER: {
    return {
      ...state,
      user: action.user,
    };
  }
  case types.GET_ACCOUNT_DATA: {
    return {
      ...state,
      accountData: action.accountData,
    };
  }
  default:
    return state;
  }
};

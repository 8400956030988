import { getFirebase } from '../../modules/firebase';

export function watchDocumentChanges(docPath, callback, oneTime) {
  const firebase = getFirebase();
  var docRef = firebase.firestore().doc(docPath);
  const unsubscriber = docRef.onSnapshot(function (doc) {
    if (doc.exists) {
      callback(doc.data());
    }

    if (oneTime) {
      unsubscriber();
    }
  });
}

export function watchCollectionChanges(
  collectionPath,
  callback,
  sendRef = false,
) {
  const firebase = getFirebase();
  var docRef = firebase.firestore().collection(collectionPath);

  docRef.onSnapshot(function (collection) {
    let data = [];

    if (!collection.empty) {
      collection.docs.forEach(document => {
        if (document.exists) {
          data.push({
            ...document.data(),
            __id: document.id,
            ref: sendRef ? document.ref : null,
          });
        }
      });
    }
    callback(data);
  });
}

export async function getDocument(docPath) {
  const firebase = getFirebase();
  var docRef = firebase.firestore().doc(docPath);
  let doc;

  try {
    doc = await docRef.get();
  } catch (e) {
    console.warn(e);
  }

  if (!doc || !doc.exists) {
    return null;
  }
  return doc.data();
}

export async function getCollection(collectionPath, queries) {
  const firebase = getFirebase();
  let data = [];
  var docRef = firebase.firestore().collection(collectionPath);

  if (queries) {
    if (!Array.isArray(queries[0])) {
      queries = [queries];
    }

    for (var index in queries) {
      const query = queries[index];
      docRef = docRef.where(...query);
    }
  }

  let documents;

  try {
    documents = await docRef.get();
  } catch {
    documents = [];
  }

  documents.forEach(document => {
    if (document.exists) {
      data.push({
        ...document.data(),
        __id: document.id,
      });
    }
  });
  return data;
}

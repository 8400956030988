import * as types from '../actions/actionTypes';

const initialState = { modal: null };

export const modalReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.SHOW_MODAL: {
    return {
      ...state,
      modal: action.modal,
    };
  }
  default:
    return state;
  }
};

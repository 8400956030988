import { getFirebase } from '../../modules/firebase';

export async function updateUserProfile({ email, password }) {
  const user = await getCurrentUser(true);

  return user?.updateProfile({ email, password });
}

export async function reauthenticate(password) {
  try {
    const firebase = getFirebase();
    const user = await getCurrentUser(false);
    let credential = firebase.auth.EmailAuthProvider.credential(
      user?.email,
      password,
    );
    return user?.reauthenticateWithCredential(credential);
  } catch {
    //
  }
}

export function getCurrentUser(signInAnon) {
  return new Promise(resolve => {
    const firebase = getFirebase();

    try {
      var user = firebase.auth().currentUser;

      if (user) {
        return resolve(user);
      }

      const unsubscribe = firebase
        .auth()
        .onAuthStateChanged(async currentUser => {
          if (!currentUser && signInAnon) {
            try {
              await firebase.auth().signInAnonymously();
            } catch {
            //
            }
          } else {
            resolve(currentUser);
          }
          unsubscribe();
        });
    } catch {
      //
    }
  });
}

export function signOut() {
  try {
    const firebase = getFirebase();
    return firebase.auth().signOut();
  } catch {
    //
  }
}

export async function getAuthToken() {
  let token;

  try {
    const user = await getCurrentUser(true);

    if (user) {
      token = await user.getIdToken();
    }
  } catch (e) {
    console.warn(e);
  }
  return token;
}

export function authStateChanged(callback) {
  const firebase = getFirebase();
  let called = false;

  if (firebase) {
    let unsubscribe = firebase.auth().onAuthStateChanged(user => {
      called = true;
      callback(user);
    });

    setTimeout(() => {
      if (!called) {
        callback();
      }
    }, 10000);
    return unsubscribe;
  }
}

export async function signInWithToken(token) {
  const firebase = getFirebase();

  if (firebase) {
    await setPersistence(true);
    const { user } = await firebase.auth().signInWithCustomToken(token);
    return user;
  }
}

export function signInWithEmailAndPassword(email, password) {
  const firebase = getFirebase();

  if (firebase) {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }
}

export function setPersistence(persist = false) {
  const firebase = getFirebase();
  const sesstionType = persist
    ? firebase.auth.Auth.Persistence.LOCAL
    : firebase.auth.Auth.Persistence.SESSION;
  return firebase.auth().setPersistence(sesstionType);
}

import * as types from '../actions/actionTypes';

const initialState = { appIsLoading: false };

export const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.APP_IS_LOADING: {
    return {
      ...state,
      appIsLoading: action.appIsLoading,
    };
  }
  default:
    return state;
  }
};

import * as types from './actionTypes';

export function showModal(modal) {
  return {
    type: types.SHOW_MODAL,
    modal,
  };
}

export function hideModal() {
  return {
    type: types.SHOW_MODAL,
    modal: null,
  };
}
import * as types from '../actions/actionTypes';

const initialState = { drops: [] };

export const dropsReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_DROPS: {
    return {
      ...state,
      drops: action.drops,
    };
  }
  default:
    return state;
  }
};
